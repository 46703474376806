<template>
  <div
    class="category-card"
    :class="{ 'level-two': levelTwo && !supplierId }"
    @click="onCardClick"
  >
    <div class="category-info">
      <h2>
        <NuxtLink
          :to="!manual && CategoryHelper.getCategoryUrl(category, undefined, undefined, supplierId)"
          @click="manual && $emit('click')"
        >
          {{ category?.title }}
        </NuxtLink>
      </h2>
      <div
        v-if="!hideSubcategoriesLinks"
        class="category-subcategories"
      >
        <div v-if="!levelTwo || +supplierId > 0">
          <NuxtLink
            v-for="item in visibleSubcategories"
            :key="item.id"
            class="category-link mm-body-medium-s mm-font-400"
            :to="!manual && CategoryHelper.getCategoryUrl(category, undefined, undefined, supplierId)"
            @click="manual && emit('clickCategory', item)"
          >
            {{ item?.title }}
          </NuxtLink>
        </div>

        <div
          class="mm-body-medium-s mm-text-link"
          :class="{ 'is-show-category-button': !category?.subcategory?.length || levelTwo }"
        >
          <NuxtLink
            v-if="!category?.subcategory?.length || levelTwo && !supplierId"
            :to="!manual && CategoryHelper.getCategoryUrl(category, undefined, undefined, supplierId)"
            @click="manual && emit('click')"
          >
            Смотреть
          </NuxtLink>

          <NuxtLink
            v-else-if="isShowAllButtonVisible"
            :to="!manual && CategoryHelper.getCategoryUrl(category, undefined, undefined, supplierId)"
            @click="manual && emit('click')"
          >
            Все категории
          </NuxtLink>
        </div>
      </div>
    </div>
    <div class="category-img">
      <BaseImg
        :src="category?.image ?? '/images/empty-product-images.png'"
        alt="category-item"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ICategory } from '../models/category.model';
import { DocumentBreakpointsUtils } from '../utils/documentBreakpoints.util';
import { CategoryHelper } from '../utils/categoryHelper.util';
import BaseImg from 'shared/components/BaseImg';

const props = defineProps<{
  // Данные для отображения
  category: ICategory;
  // Скрыть подкатегории, заменить их на кнопку "Смотреть"
  levelTwo?: boolean;
  /** Скрыть ссылки на подкатегории для неавторизованных пользователей */
  hideSubcategoriesLinks?: boolean;
  /** Ручное управление переходами */
  manual?: boolean;
  /** ID поставщика */
  supplierId?: number;
}>();

const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'clickCategory', category: ICategory): void;
}>();

const DEFAULT_VISIBLE_SUBCATEGORIES_COUNT = 5;
const isShowAllSubcategories = ref(false);
const visibleSubcategories = computed(() =>
  props.category?.subcategory?.slice(
    0,
    isShowAllSubcategories.value ? props.category?.subcategory?.length : DEFAULT_VISIBLE_SUBCATEGORIES_COUNT,
  ),
);
const isShowAllButtonVisible = computed(
  () => !isShowAllSubcategories.value && props.category?.subcategory?.length > DEFAULT_VISIBLE_SUBCATEGORIES_COUNT,
);
const isCardClickable = computed(() => DocumentBreakpointsUtils.getIsRenderedMobile() || DocumentBreakpointsUtils.getIsRenderedTablet());

function onCardClick() {
  if (!isCardClickable.value) {
    return;
  }

  if (props.manual) {
    return emit('click');
  }

  navigateTo(CategoryHelper.getCategoryUrl(props.category));
}
</script>

<style lang="scss">
@import 'styles/base/common/_variables';

.category-card {
  display: flex;
  justify-content: space-between;
  height: 360px;
  padding: 32px;

  .category-info {
    display: flex;
    flex-direction: column;

    .category-subcategories {
      display: flex;
      flex-direction: column;
      height: 100%;

      .category-link {
        margin-bottom: 12px;
        line-height: 20px;
        display: flex;
        cursor: pointer;
        text-decoration: none;
        transition: 0.3s ease-in-out;

        &:hover {
          color: $link;
        }
      }

      .category-show-link {
        text-decoration: none;
        transition: 0.3s ease-in-out;

        &:hover {
          color: $link;
        }
      }

      .mm-text-link {
        &.is-show-category-button {
          margin-top: auto;
        }

        p {
          margin-bottom: 0;
        }

        a {
          text-decoration: none;
          margin-bottom: 0;
          display: block;
          color: $link;
          font-weight: 500;
        }
      }
    }
  }

  .category-img {
    width: 100px;
    height: 100px;

    img {
      width: inherit;
      height: inherit;
      object-fit: contain;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .category-info {
    h2 {
      a {
        max-width: 271px;
        margin-bottom: 24px;
        line-height: 28px;
        min-height: 56px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        display: block;
        text-decoration: none;
        font-size: 20px;
        font-weight: 400;
        color: $text-black;

        &:hover {
          color: $link;
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .category-card {
    &.level-two {
      height: 216px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .category-card {
    padding: 24px;
    max-height: 148px;

    &.level-two {
      height: 189px;
    }

    .category-info {
      h2 a {
        max-width: none;
        min-height: fit-content;
        margin-bottom: 52px;
      }

      .category-subcategories {
        display: none;
      }
    }

    .category-img {
      width: 90px;
      height: auto;
      margin-left: 34px;

      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .category-card {
    justify-content: flex-start;
    align-items: center;
    padding: 20px 16px;
    max-height: 96px;

    .category-info {
      justify-content: center;
      margin-right: auto;

      h2 {
        display: flex;
        align-items: center;

        a {
          font-size: 16px;
          line-height: 22px;
          min-height: 0;
          margin-bottom: 0;
        }
      }
    }

    .category-img {
      width: 56px;
      height: 56px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .category-card {
    width: 320px;
    align-items: center;

    .category-title {
      max-width: 196px;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
}
</style>
